<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">身份信息被盗用，如何证明“我不是真的我”...</div>
            <p class="time">2020-03-27</p>
            
            <p class="space_content">
                前段时间，李冬先生无意间查看自己的芝麻信用评分时发现，在“信用资产”一栏中，赫然出现了一家注册地为广州的公司，而他自己则是这家公司的法定代表人，对此他竟毫不知情。
            </p>
            <p class="space_content">
                随后，李冬先生在国家企业信用信息公示系统资料里查询到，这家公司于2017年6月成立，注册资本为850万元，登记状态为“在营(开业)企业”，且发现自己从未签过此份注册材料。而如今因未按时公示年度报告，此公司在不久前就被列入了经营异常名录。
            </p>
            <p class="space_content">
                李冬先生意识到自己的身份证可能被人冒用了，这就意味着，其以后可能无法成立其他公司，社会信用记录、贷款等也将会受到影响。
            </p>
            <p class="space_content">
                为了及时止损，李冬先生需要拿出证据，证明是别人冒名顶替在广州注册登记了公司，并申请撤销该公司的登记。
            </p>
            <div class="col8" style="margin:0 auto">
                <img class="col8"  v-lazy="require('../../assets/case/2-1-1.png')">
            </div>
            <p class="titlee2" >如何证明登记注册的“我不是真的我”</p>
            <p class="space_content">
                为了自证清白，李冬先生委托福建历思司法鉴定所对注册原件（在市场监督管理局的帮助下取得注册原件）上的公司法定代表人签名“李冬”进行笔迹鉴定，同时提供了其在2017年至2019年（提取与注册原件同时期的笔迹）银行业务办理相关文件的签名作为样本。
            </p>
            <p class="space_content">
                经过福建历思司法鉴定所专家的鉴定发现，注册原件上的签名笔迹和样本签名笔迹在概貌特征和细节特征存在明显差异。再对所检特征综合分析判断后，得出注册原件上的公司法定代表人签名“李冬”笔迹与李冬样本笔迹不是出自同一人的笔迹。
            </p>
             <div class="col8" style="margin:0 auto">
                <img class="col8"  v-lazy="require('../../assets/case/2-1-2.png')">
            </div>
            <p class="space_content">
                鉴定意见书一出，为李冬先生申请撤销该公司的登记提供了有力的证据，并顺利地撤销此公司的登记。
            </p>
            <p class="titlee2" >历思寄语：</p>


           
            
            <p class="space_content">
                李冬先生的遭遇并不是个案，近年来“被法人”“被高管”“被就业”等各种身份冒用的行为频繁出现。要是被他人冒用，导致自己被列入失信被执行人的“黑名单”，被害人一旦需要办理涉及税务和银行方面的业务时，都无法办理，甚至被限制乘坐高铁、飞机，真是“人在家中坐，祸从天上来”。
            </p>
            <p class="space_content">
                冒用他人身份证注册公司等这些事件，都会涉及到公司的文件的签名。当事人也可以向公安机关或是事件的主管部门申请提出进行笔迹鉴定，以核实确认签名的人是否为当事人本人，来证明自己的清白。
            </p>
            <p class="space_content">
                总而言之，要尽量保管好自己的身份证，以免身份证信息可能被不法分子利用。一旦自己的权利受到侵害，也请一定要拿起法律的武器保护自己。
            </p>
        </div>
        <div class="flex wrap col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇：</p>
                    <router-link class="a" :to="'/case/trace_case/1'"><p class="ellipsis1">变造票据，这样能遮掩过去吗？</p></router-link>

                    
                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇：</p>无
                    
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/case/trace_case'"><el-button type="primary">返回列表</el-button></router-link >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>
<style scoped>
.space_content{
    margin-bottom:2rem;
    
}
</style>